<template>
  <div>
    <div ref="map" class="mgl-map" />
    <!-- map style control -->
    <v-card
      v-if="showStyles"
      class="style-group"
      elevation="0"
      color="transparent"
      tile
    >
      <v-btn-toggle
        v-model="mapStyle"
        borderless
        dense
        background-color="transparent"
        @change="setStyle"
      >
        <v-btn
          v-for="(style, iStyle) in mapStyles"
          :key="iStyle"
          :value="style.value"
          :class="{
            'bg-ease': mapStyle === style.value
          }"
          small
          :dark="disabledStyles"
          :disabled="disabledStyles"
        >
          <v-icon left small :color="mapStyle === style.value ? 'white' : ''">
            {{ style.icon }}
          </v-icon>
          <span class="hidden-sm-and-down">{{ style.text }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-card>

    <v-card v-if="showControls" class="zoom-card px-3">
      Zoom {{ currentZoom.toFixed(1) }}
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { mapState } from 'vuex'

import { Layer3DNode } from '@/models'

const mapStyles = (vm) => [
  {
    text: vm.$t('default'),
    icon: 'mdi-map-marker',
    value: 'mapbox/outdoors-v11'
  },
  {
    text: vm.$t('satellite'),
    icon: 'mdi-satellite',
    value: 'mapbox/satellite-streets-v11'
  },
  {
    text: 'OSM',
    icon: 'mdi-map',
    value: 'sfdff327/ckkgdfbne09f517pn261bf5xn'
  }
]

export default {
  name: 'MglMap',

  props: {
    value: {
      type: mapboxgl.Map,
      default: null
    },
    accessToken: {
      type: String,
      default: ''
    },
    center: {
      type: Array,
      default: () => [121, 24]
    },
    zoom: {
      type: Number,
      default: 8
    },
    showStyles: {
      type: Boolean,
      default: true
    },
    disabledStyles: {
      type: Boolean,
      default: true
    },
    showControls: {
      type: Boolean,
      default: true
    }
  },

  data: (vm) => ({
    map: vm.value,
    mapStyle: mapStyles(vm)[0].value,
    currentZoom: vm.zoom,
    navigationControl: null,
    scaleControl: null
  }),

  computed: {
    ...mapState({
      layerTree: (state) => state.map.layerTree
    }),
    mapStyles
  },

  watch: {
    showControls(newVal, oldVal) {
      if (newVal && oldVal === false) {
        this.map.addControl(this.navigationControl, 'bottom-right')
      }

      if (!newVal && oldVal === true) {
        this.map.removeControl(this.navigationControl)
      }
    }
  },

  mounted() {
    mapboxgl.accessToken = this.accessToken
    this.init()
  },

  beforeDestroy() {
    this.map.remove()
  },

  methods: {
    init() {
      this.navigationControl = new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true
      })
      this.scaleControl = new mapboxgl.ScaleControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true
      })
      this.map = new mapboxgl.Map({
        container: this.$refs.map,
        style: `mapbox://styles/${this.mapStyle}`, // style URL
        center: this.center, // starting position [lng, lat]
        zoom: this.zoom, // starting zoom
        // pitch: 60,
        antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
      })
        .addControl(this.scaleControl, 'bottom-right')
        .on('load', this.onMapLoad)
        .on('zoom', (event) => {
          this.currentZoom = event.target.getZoom()
        })

      if (this.showControls) {
        this.map.addControl(this.navigationControl, 'bottom-right')
      }

      this.$emit('input', this.map)
    },
    onMapLoad() {
      this.$emit('load', this.map)
    },
    async setStyle(styleID) {
      // NOTE: 切換style類型得keep原本style其他設定
      // ref: https://github.com/mapbox/mapbox-gl-js/issues/4006

      const currentStyle = this.map.getStyle()

      const { data: newStyle } = await axios.get(
        `https://api.mapbox.com/styles/v1/${styleID}?access_token=${this.accessToken}`
      )

      // ensure any sources from the current style are copied across to the new style
      newStyle.sources = Object.assign(
        {},
        currentStyle.sources,
        newStyle.sources
      )

      // find the index of where to insert our layers to retain in the new style
      let labelIndex = newStyle.layers.findIndex((el) => {
        return el.id === 'waterway-label'
      })

      // default to on top
      if (labelIndex === -1) {
        labelIndex = newStyle.layers.length
      }
      const appLayers = currentStyle.layers.filter((el) => {
        // if (!el.source) {
        //   console.log(el)
        // }

        // app layers are the layers to retain, and these are any layers which have a different source set
        return (
          el.source &&
          el.source !== 'mapbox://mapbox.satellite' &&
          el.source !== 'mapbox' &&
          el.source !== 'composite'
        )
      })

      newStyle.layers = [
        ...newStyle.layers.slice(0, labelIndex),
        ...appLayers,
        ...newStyle.layers.slice(labelIndex, -1)
      ]

      this.map.setStyle(newStyle)

      this.map.once('idle', this.wattingToAddLayer3DNode)
    },
    wattingToAddLayer3DNode() {
      if (!this.map.isStyleLoaded()) {
        return setTimeout(this.wattingToAddLayer3DNode, 200)
      }

      const layer3DNodes = this.layerTree.getLeavesDF(
        this.layerTree.root,
        Layer3DNode
      )

      layer3DNodes.forEach((layer3DNode) => {
        if (!this.layerTree.hasLayer(layer3DNode)) {
          this.layerTree.addLayer(layer3DNode, null, false)
          this.layerTree.setMaplayerVisibility(layer3DNode)
        }
      })

      this.layerTree.updateLayerNodes(this.layerTree.root, false, false)
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: $map-ctrl-box-shadow;
}
</style>

<style lang="scss" scoped>
.mgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.style-group {
  position: absolute;
  bottom: 30px;
  left: 280px;
  z-index: 1;

  .v-item-group.v-btn-toggle {
    .v-btn {
      background-color: hsla(0deg, 0%, 100%, 0.5);

      &--active {
        color: white;
      }
    }
  }
}

.zoom-card {
  position: absolute;
  right: 48px;
  bottom: 62px;
  z-index: 1;
  min-width: 7rem;
  text-align: center;
  border-radius: 4px;
}
</style>
