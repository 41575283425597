<template>
  <v-dialog
    v-model="dialog"
    max-width="870"
    content-class="modflow-params-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <!-- <v-btn class="dialog-activator" v-bind="attrs" v-on="on">
          屬性設定
        </v-btn> -->
      </slot>
    </template>

    <v-card min-height="400">
      <v-card-title>
        <v-row class="px-3 my-0 py-1" align="end">
          <v-col class="modflow-divider pa-0 mb-3 mr-11">
            <template v-if="editFileNode">
              <template
                v-for="(breadcrumb, iBreadcrumb) in editFileNode.breadcrumbs"
              >
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap"
                  style="font-size: 0.9rem;"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < editFileNode.breadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="primary"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </template>
            <span
              v-else
              class="text-h6"
            >{{ $t('new_file') }}</span>
          </v-col>
          <v-col
            v-if="hasFeature"
            class="pl-0 mr-4"
            cols="auto"
          >
            <v-btn
              class="modflow-btn"
              color="secondary"
              small
              :loading="isLoading"
              @click="clearParameters"
            >
              {{ $t('use_default') }}
            </v-btn>
          </v-col>
          <v-col
            v-else-if="!!editModelFileNode"
            class="pl-0 mr-4"
            cols="auto"
          >
            <v-btn
              class="modflow-btn"
              color="secondary"
              small
              :disabled="isLoading"
              @click="applyModalParameters"
            >
              {{ $t('apply_global_params') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <SaveAsDialog
              v-if="!isEdit && layerNode"
              :file-content="modflowParams"
              :parent-maplayer-uuid="layerNode.s3Uuid"
              :edit-file-node.sync="editFileNode"
            >
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  v-bind="attrs"
                  class="modflow-btn"
                  color="primary"
                  dark
                  small
                  v-on="on"
                >
                  {{ $t('save_as_new_file') }}
                </v-btn>
              </template>
            </SaveAsDialog>
            <v-btn
              v-else
              class="modflow-btn"
              color="primary"
              dark
              small
              :loading="isLoading"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <component
          :is="currentParametersComponent"
          v-if="editParameters"
          :parameters="editParameters"
        />
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer />

        <v-btn
          class="action-btn"
          color="secondary"
          rounded
          :disabled="isLoading"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="isEdit"
          class="action-btn ml-12"
          rounded
          :disabled="isLoading"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Point from './Point/PointTabs'
import LineString from './LineString/LineStringTabs'
import Polygon from './Polygon/PolygonTabs'
import SaveAsDialog from '@/components/Simulation/SaveAsDialog.vue'

import { LayerNode } from '@/models'

import { cloneDeep } from 'lodash'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ModflowParametersDialog',

  components: {
    'Parameters-Point': Point,
    'Parameters-LineString': LineString,
    'Parameters-Polygon': Polygon,
    SaveAsDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    layerNode: {
      // For feature edit modflow params
      type: LayerNode,
      default: null
    },
    featureIndex: {
      // For feature edit modflow params
      type: [Number, String],
      default: null
    },
    layerIndex: {
      // For feature edit modflow params
      type: [Number, String],
      default: null
    }
  },

  data: () => ({
    show: false,
    isLoading: false
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode,
      modflowParams: state => state.simulation.modflow.modflowParams,
      editModelFileNode: state => state.simulation.editModelFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },
    editFileNode: {
      get() {
        return this.layerNode?.editSimParamFileNode
      },
      set(newVal) {
        this.layerNode.setProperties({
          editSimParamFileNode: newVal
        })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    isEdit() {
      return !!this.editFileNode
    },
    hasFeature() {
      return this.featureIndex != null
    },
    hasLayer() {
      return this.layerIndex != null
    },
    editParameters() {
      // edit default
      if (!this.hasFeature) {
        return this.modflowParams.default
      }

      // edit feature
      if (!this.hasLayer) {
        return this.modflowParams.features[this.featureIndex]
      }

      // edit feature layer
      if (this.hasLayer) {
        return this.modflowParams.features[this.featureIndex]?.[this.layerIndex]
      }

      return this.modflowParams.default
    },
    currentParametersComponent() {
      let component = ''
      switch (this.layerNode?.type) {
        case 'circle':
          component = 'Point'
          break
        case 'line':
          component = 'LineString'
          break
        case 'fill':
          component = 'Polygon'
          break
      }

      if (!component) return

      return `Parameters-${component}`
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    dialog: {
      handler(newVal) {
        newVal ? this.init() : this.clearEditParameters()
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    }
  },

  methods: {
    init() {
      if (this.editFileNode) {
        this.$store.dispatch('simulation/modflow/editParameters', {
          layerNode: this.layerNode,
          editFileNode: this.editFileNode,
          featureIndex: this.featureIndex,
          layerIndex: this.layerIndex
        })

        return
      }

      this.$store.dispatch('simulation/modflow/createNewParameters', {
        layerNode: this.layerNode
      })
    },
    clearEditParameters() {
      this.$store.dispatch('simulation/modflow/initModflowParameters')
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.editFileNode = null
      this.close()
    },
    save() {
      this.isLoading = true
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: this.editFileNode,
        jsonContent: this.modflowParams
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_edited')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    applyModalParameters() {
      this.$store.dispatch('simulation/modflow/applyModalParameters', {
        layerNode: this.layerNode
      })
    },
    clearParameters() {
      if (!this.hasFeature) {
        return
      }

      const jsonContent = cloneDeep(this.modflowParams)
      this.hasLayer
        ? jsonContent.features[this.featureIndex].splice(this.layerIndex, 1, null)
        : jsonContent.features.splice(this.featureIndex, 1, null)

      this.isLoading = true
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: this.editFileNode,
        jsonContent
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_edited')
          })

          this.close()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
.v-dialog.modflow-params-dialog {
  display: flex;
}
</style>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.v-dialog {
  .v-card {
    display: flex;
    flex-direction: column;

    .v-card__text {
      overflow-y: auto;
      color: #000;
      flex-grow: 1;
    }

    .v-card__actions {
      flex-grow: 0;
    }

    .v-btn:focus {
      outline: none;
    }

    .modflow-divider {
      // height: 0;
      border-bottom: solid 2px $color-primary;
    }

    .modflow-btn {
      font-size: 1rem;
      border-radius: 6px;
    }
  }
}
</style>
