<template>
  <v-sheet color="grey lighten-3 d-flex flex-column" height="100%"
           style="position: relative;"
  >
    <v-row
      class="px-8 py-3 bg-ease flex-grow-0 flex-nowrap"
      no-gutters
      style="overflow-x: hidden;"
    >
      <v-col cols="auto">
        <v-btn icon small dark @click="$emit('click:back')">
          <v-icon>mdi-undo-variant</v-icon>
        </v-btn>
      </v-col>
      <v-col class="step-title white--text d-flex align-center" style="max-width: 60%;">
        <span
          class="d-block text-truncate"
          :title="dashboard.title"
        >{{ dashboard.title }}</span>
        <span
          v-if="hasLayerLabel"
          :title="layerLabel"
        >({{ truncatedLayerLabel }})</span>
      </v-col>

      <!-- <v-col
        v-if="hasLayerLabel"
        class="step-title white--text d-flex align-center"
      >
        <v-row no-gutters>
          <v-col cols="auto">
            (
          </v-col>
          <v-col class="text-truncate" cols="auto">
            {{ layerLabel }}
          </v-col>
          <v-col cols="auto">
            )
          </v-col>
        </v-row>
      </v-col> -->

      <v-spacer />

      <v-col class="ml-2" col="auto">
        <TimezoneSelect v-model="timezone" class="pt-0 mt-0" dark prepend-inner-icon="mdi-earth" />
      </v-col>
    </v-row>

    <v-row
      no-gutters
      align="start"
      align-content="start"
      style=" overflow-y: auto;height: calc(100% - 53px);"
    >
      <v-col
        v-for="panel in panels"
        :key="`${dashboard.title}-${panel.id}`"
        cols="12"
        style="height: 200px;"
      >
        <PanelCard
          :dashboard="dashboard"
          :panel="panel"
          readonly
          :use-teamplating="properties"
        />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snack"
      :timeout="10000"
      color="grey"
      bottom
      absolute
    >
      {{ $t('loading_panels_please_wait') }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-sheet>
</template>

<script>
import PanelCard from '@/components/PanelCard'
import TimezoneSelect from '@/components/TimezoneSelect'

import { truncate } from 'lodash'

export default {
  name: 'FeaturePanels',

  components: {
    PanelCard,
    TimezoneSelect
  },

  props: {
    dashboard: {
      type: Object,
      required: true
    },
    properties: {
      type: Object,
      default: () => ({})
    },
    layerLabel: {
      type: [String, Number],
      default: null
    }
  },

  data: () => ({
    snack: true
  }),

  computed: {
    timezone: {
      get() {
        return this?.$route?.query?.timezone || 'utc'
      },
      set(newVal) {
        if (newVal === this?.$route?.query?.timezone) return

        const { name = '', params = {}, query = {} } = this.$route || {}

        this.$router.replace({
          name,
          params,
          query: {
            ...query,
            timezone: newVal
          }
        })
      }
    },

    panels() {
      const panels = this?.dashboard?.panels

      if (!Array.isArray(panels)) return []

      return panels.sort((a, b) => b.id - a.id)
    },
    hasLayerLabel() {
      return this.layerLabel != null && this.layerLabel !== ''
    },
    truncatedLayerLabel() {
      return truncate(`${this.layerLabel}`, { length: 15 })
    }
  },

  watch: {
    properties: {
      handler() {
        this.snack = true
      }
    },
    timezone: {
      handler() {
        this.snack = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  margin-left: 0.5em;
  font-size: 1.125rem;
}
</style>
